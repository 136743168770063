
$(function () {
	$(window).on('load resize', function(e) {
		$('.jq_submenu').each(function() {
			var $el = {
				nav: $(this),
				items: $('.jq_submenu_item', this),
				eclipse: $('.jq_submenu_eclipse', this),
				dropdown: $('.jq_submenu_dropdown', this)
			};

			$el.eclipse.removeClass('d-flex');

			 $el.items.show();

			$el.dropdown.empty();

			var viewport = $el.nav.width() - $el.eclipse.outerWidth();
			var menuWidth = $el.nav[0].offsetWidth;
			var scrollWidth = $el.nav[0].scrollWidth;

			if (menuWidth < scrollWidth) {
				$el.eclipse.addClass('d-flex');

				var eclipsing = false;

				$el.items.each(function() {
					var position = $(this).position().left;
					var width = $(this).width();

					if (eclipsing || viewport <= position + width || viewport < position) {
						eclipsing = true;

						var $item = $(this).clone();

						$el.dropdown.append($item.removeClass('jq_submenu_item'));

						if ($(this).hasClass('on').length > 0) {
							$el.eclipse.addClass('on');
						}

						$(this).hide();
					}
				});
			}
		});
	});

	if ('ontouchstart' in document.documentElement) {
		$('.jq_submenu_eclipse').on('click', function() {
			$('.jq_submenu_dropdown', this).slideToggle();
		});
	}
});