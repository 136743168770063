$(function() {
	if ($('.jq_tabs').length) {
		if (!window.IntersectionObserver) {
			$.getScript('https://unpkg.com/intersection-observer/intersection-observer.js', function(data, textStatus, jqxhr) {
				if (jqxhr.status == 200) loadTabs();
			});
		} else {
			loadTabs();
		}
	}

	function loadTabs() {
		$('.jq_tabs').each(function() {
			var $el = {
				container: $(this),
				nav: $('.jq_tabs_nav', this),
				list: $('.jq_tabs_nav_list', this),
				tabs: $('.jq_tabs_nav_tab', this),
				buttons: $('.jq_tabs_nav_button', this),
				arrows: $('.jq_tabs_nav_arrow', this),
				panels: $('.jq_tabs_panel', this)
			};

			var navigationPosition = 0;

			var navigationTotal = $el.tabs.length - 1;

			var navigationViewport = new IntersectionObserver(function(tabs) {
				$.each(tabs, function(i, tab) {
					if (tab.isIntersecting) {
						navigationPosition = $(tab.target).index();
					}
				});
			}, {
				root: $el.list[0],
				rootMargin: '0% -70% 0% -10%',
				threshold: 0
			});

			$el.tabs.each(function() {
				navigationViewport.observe(this);
			});

			$(window).on('load resize', function() {
				initTabsScroll($el.tabs);

				toggleTabsArrows($el.list, $el.arrows);
			});

			$el.list.on('scroll', function(){
				toggleTabsArrows($(this), $el.arrows);
			});

			$el.arrows.on('click', function() {
				if ($(this).index() == 1) { // prev
					navigationPosition--;
					if (navigationPosition < 0) navigationPosition = 0;
				} else if ($(this).index() == 2) { // next
					navigationPosition++;
					if (navigationPosition > navigationTotal) navigationPosition = navigationTotal;
				}

				var scrollTo = $el.tabs.eq(navigationPosition).data('position-left');

				$el.list.scrollLeft(scrollTo);
			});

			$el.buttons.on('click', function(e) {
				e.preventDefault();

				if ($(this).hasClass('on')) {
					return false;
				}

				var index = $(this).attr('href');

				var $tab = $(this).parent();

				if ($el.list[0].offsetWidth + $el.list.scrollLeft() < $el.list[0].scrollWidth) {
					navigationPosition = $tab.index();
				}

				$el.buttons.not(this).removeClass('on');

				$(this).addClass('on');

				if ($tab.data('position-left') < $el.list.scrollLeft() || ($el.list[0].offsetWidth + $el.list.scrollLeft() - 80) < ($tab.data('position-left') + $tab.outerWidth())) {
					$el.list.scrollLeft($tab.data('position-left'));
				}

				$el.panels.filter(':visible').slideUp(400, function() {
					$el.panels.filter(index).slideDown(400);

					if (typeof magicGrids !== 'undefined') {

						
						$.each(magicGrids, function(magicIndex, magicGrid) {
							var $magicGrid = $el.panels.filter(index).find(magicGrid.selector);

							if ($magicGrid.length > 0 && $magicGrid.height() == 0) {
								magicGrid.instance.positionItems();
							}
						});

					}
				});
			});
		});
	}

	function initTabsScroll($tabs) {
		var totalWidth = 0;

		$tabs.each(function(i) {
			$(this).data('position-left', totalWidth - (i != 0 ? 40 : 0));

			totalWidth += $(this).outerWidth();
		});
	}

	function toggleTabsArrows($menu, $arrows) {
		var menuWidth = $menu[0].offsetWidth;
		var scrollWidth = $menu[0].scrollWidth;
		var scrollPosition = $menu.scrollLeft();
		
		if (menuWidth < scrollWidth) {
			if (scrollPosition <= 40) {
				$arrows.eq(0).filter(':visible').fadeOut();
				$arrows.eq(1).filter(':hidden').fadeIn();
			} else if (scrollPosition > 40 && scrollPosition <= scrollWidth - menuWidth - 30) {
				$arrows.filter(':hidden').fadeIn();
			} else {
				$arrows.eq(0).filter(':hidden').fadeIn();
				$arrows.eq(1).filter(':visible').fadeOut();
			}
		} else {
			$arrows.fadeOut();
		}
	}
});