$(function() {
	$(window).on('load resize', function() {
		$('.jq_collapse').each(function() {
			var $el = $(this);

			$el.removeClass('collapsed');

			$('.jq_collapse_control', $el).hide();

			if ($el.prop('clientHeight') < $el.prop('scrollHeight')) {
				$el.addClass('collapsed');

				$('.jq_collapse_control', $el).show();
			}
		});
	});

	$('.jq_collapse_control').on('click', function() {
		var $el = $(this).parent('.jq_collapse');

		if ($(this).hasClass('on')) {
			$el.animate({ height: $el.data('h') }, 300, function() {
				$el.removeAttr('data-h style');
			});

			$(this).removeClass('on');
		} else {
			var t = $el.height();

			$el.data('h', t)
			   .css({ height: t, 'max-height': 'none' })
			   .animate({ height: $el.prop('scrollHeight') }, 300);

			$(this).addClass('on');
		}
	});
});