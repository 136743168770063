$(function () {
	var headerBreakpoint = window.matchMedia("(min-width: 62em)");

	headerBreakpoint.addListener(closeMenuOnResize);

	closeMenuOnResize(headerBreakpoint);

	$('.jq_menu_status').change(function() {
		if (this.checked) {
			$('html, body').addClass('no-scroll');

			$(document).on('keyup', { status: this }, closeMenuOnEscape);
		} else {
			$('html, body').removeClass('no-scroll');

			$(document).off('keyup', closeMenuOnEscape);
		}
	});

	function closeMenuOnResize(breakpoint) {
		if (breakpoint.matches) {
			$('.jq_menu_status').prop('checked', false).trigger('change');
		}
	}

	function closeMenuOnEscape(event) {
		if (event.keyCode === 27) {
			var args = event.data,
				status = args.status;
			$(status).prop('checked', false).trigger('change');
		}
	}

	if ($('.jq_headroom').length && Headroom.cutsTheMustard) {
		// no support for IE 11 since headroom 0.10.3 https://github.com/WickyNilliams/headroom.js/releases/tag/v0.10.3
		var headroom = new Headroom($('.jq_headroom')[0]);
		headroom.init();
	}

  //otev�r�n� str�nek v menu do nov�ho okna je�t� je t�eba v menu v html m�t id a data-id
  var target = ["26","27","38","41","43","47","48","49","50","51","52"];

  $(".m-nav-main__link").click(function(){

    var a = $(this).attr("data-id");
    var h = $(this).attr("href");

    if(jQuery.inArray($(this).attr("data-id"), target) != -1){

      $(this).attr('target','_blank');

    }

  });

  $(".m-nav-top__link").click(function(){

    var a = $(this).attr("data-id");
    var h = $(this).attr("href");

    if(jQuery.inArray($(this).attr("data-id"), target) != -1){

      $(this).attr('target','_blank');

    }

  });

  $(".m-footer__nav--item").find("a").click(function(){

    var a = $(this).attr("data-id");
    var h = $(this).attr("href");

    if(jQuery.inArray($(this).attr("data-id"), target) != -1){

      $(this).attr('target','_blank');

    }

  });


});